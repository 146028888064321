{
   "AGI_LocusCode" : {
      "description" : "Arabidopsis Genome Initiative",
      "name" : "AGI_LocusCode",
      "url_syntax" : "http://arabidopsis.org/servlets/TairObject?type=locus&name=[example_id]",
      "website" : "http://www.arabidopsis.org"
   },
   "ARBA" : {
      "description" : "ARBA machine learning models for automatic annotation of UniProtKB unreviewed entries",
      "name" : "ARBA",
      "url_syntax" : "https://www.uniprot.org/arba/[example_id]",
      "website" : "https://www.uniprot.org/arba/"
   },
   "AlphaFold" : {
      "description" : "AlphaFold Protein Structure Database",
      "name" : "AlphaFold",
      "url_syntax" : "https://alphafold.ebi.ac.uk/entry/[example_id]",
      "website" : "https://alphafold.ebi.ac.uk/"
   },
   "CDD" : {
      "description" : "Conserved Domain Database at NCBI",
      "name" : "CDD",
      "url_syntax" : "http://www.ncbi.nlm.nih.gov/Structure/cdd/cddsrv.cgi?uid=[example_id]",
      "website" : "https://www.ncbi.nlm.nih.gov/cdd"
   },
   "CGD" : {
      "description" : "Candida Genome Database",
      "name" : "CGD",
      "url_syntax" : "http://www.candidagenome.org/cgi-bin/locus.pl?dbid=[example_id]",
      "website" : "http://www.candidagenome.org/"
   },
   "CHEBI" : {
      "description" : "Chemical Entities of Biological Interest",
      "name" : "CHEBI",
      "url_syntax" : "http://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:[example_id]",
      "website" : "http://www.ebi.ac.uk/chebi/"
   },
   "CL" : {
      "description" : "Cell Type Ontology",
      "name" : "CL",
      "url_syntax" : "http://purl.obolibrary.org/obo/CL_[example_id]",
      "website" : "http://cellontology.org"
   },
   "ComplexPortal" : {
      "description" : "Complex Portal database of macromolecular complexes",
      "name" : "ComplexPortal",
      "url_syntax" : "https://www.ebi.ac.uk/complexportal/complex/[example_id]",
      "website" : "https://www.ebi.ac.uk/complexportal"
   },
   "DDBJ" : {
      "description" : "DNA Databank of Japan",
      "name" : "DDBJ",
      "url_syntax" : "http://getentry.ddbj.nig.ac.jp/getentry/na/[example_id]",
      "website" : "https://www.ddbj.nig.ac.jp"
   },
   "DOI" : {
      "description" : "Digital Object Identifier",
      "name" : "DOI",
      "url_syntax" : "http://dx.doi.org/[example_id]",
      "website" : "http://dx.doi.org/"
   },
   "DTU" : {
      "description" : "DTU Health Tech Services",
      "name" : "DTU",
      "url_syntax" : null,
      "website" : "http://www.cbs.dtu.dk/services"
   },
   "EC" : {
      "description" : "Enzyme Commission",
      "name" : "EC",
      "url_syntax" : "https://enzyme.expasy.org/EC/[example_id]",
      "website" : "http://enzyme.expasy.org/"
   },
   "EMBL" : {
      "description" : "EMBL Nucleotide Sequence Database",
      "name" : "EMBL",
      "url_syntax" : "http://www.ebi.ac.uk/cgi-bin/emblfetch?style=html&Submit=Go&id=[example_id]",
      "website" : "http://www.ebi.ac.uk/embl/"
   },
   "ENA" : {
      "description" : "European Nucleotide Archive",
      "name" : "ENA",
      "url_syntax" : "http://www.ebi.ac.uk/ena/data/view/[example_id]",
      "website" : "http://www.ebi.ac.uk/ena/"
   },
   "ENSEMBL" : {
      "description" : "Ensembl database of automatically annotated genomic data",
      "name" : "ENSEMBL",
      "url_syntax" : "http://www.ensembl.org/id/[example_id]",
      "website" : "http://www.ensembl.org/"
   },
   "FB" : {
      "description" : "FlyBase",
      "name" : "FB",
      "url_syntax" : "http://flybase.org/reports/[example_id].html",
      "website" : "http://flybase.org/"
   },
   "GEO" : {
      "description" : "NCBI Gene Expression Omnibus",
      "name" : "GEO",
      "url_syntax" : "http://www.ncbi.nlm.nih.gov/sites/GDSbrowser?acc=[example_id]",
      "website" : "http://www.ncbi.nlm.nih.gov/geo/"
   },
   "GO" : {
      "description" : "Gene Ontology Database",
      "name" : "GO",
      "url_syntax" : "http://amigo.geneontology.org/amigo/term/GO:[example_id]",
      "website" : "http://amigo.geneontology.org/"
   },
   "HAMAP" : {
      "description" : "High-quality Automated and Manual Annotation of microbial Proteomes",
      "name" : "HAMAP",
      "url_syntax" : "http://hamap.expasy.org/unirule/[example_id]",
      "website" : "http://hamap.expasy.org/"
   },
   "HGNC" : {
      "description" : "HUGO Gene Nomenclature Committee",
      "name" : "HGNC",
      "url_syntax" : "https://www.genenames.org/data/gene-symbol-report/#!/hgnc_id/HGNC:[example_id]",
      "website" : "http://www.genenames.org/"
   },
   "ISBN" : {
      "description" : "International Standard Book Number",
      "name" : "ISBN",
      "url_syntax" : "https://www.worldcat.org/search?q=bn%3A[example_id]",
      "website" : "https://www.worldcat.org"
   },
   "InterPro" : {
      "description" : "InterPro database of protein domains and motifs",
      "name" : "InterPro",
      "url_syntax" : "http://www.ebi.ac.uk/interpro/entry/[example_id]",
      "website" : "http://www.ebi.ac.uk/interpro/"
   },
   "JaponicusDB" : {
      "description" : "JaponicusDB",
      "name" : "JaponicusDB",
      "url_syntax" : "https://www.japonicusdb.org/gene/[example_id]",
      "website" : "https://www.japonicusdb.org/"
   },
   "MGI" : {
      "description" : "Mouse Genome Informatics",
      "name" : "MGI",
      "url_syntax" : "http://www.informatics.jax.org/accession/[example_id]",
      "website" : "http://www.informatics.jax.org/"
   },
   "ModBase" : {
      "description" : "ModBase comprehensive Database of Comparative Protein Structure Models",
      "name" : "ModBase",
      "url_syntax" : "http://salilab.org/modbase/searchbyid?databaseID=[example_id]",
      "website" : "http://modbase.compbio.ucsf.edu/"
   },
   "PANTHER" : {
      "description" : "Protein ANalysis THrough Evolutionary Relationships Classification System",
      "name" : "PANTHER",
      "url_syntax" : "http://www.pantherdb.org/panther/lookupId.jsp?id=[example_id]",
      "website" : "http://www.pantherdb.org/"
   },
   "PDB" : {
      "description" : "Protein Data Bank",
      "name" : "PDB",
      "url_syntax" : "https://www.rcsb.org/structure/[example_id]",
      "website" : "https://www.rcsb.org/pdb/"
   },
   "PIRSF" : {
      "description" : "PIR Superfamily Classification System",
      "name" : "PIRSF",
      "url_syntax" : "http://pir.georgetown.edu/cgi-bin/ipcSF?id=[example_id]",
      "website" : "http://pir.georgetown.edu/pirsf/"
   },
   "PMID" : {
      "description" : "PubMed",
      "name" : "PMID",
      "url_syntax" : "http://www.ncbi.nlm.nih.gov/pubmed/[example_id]",
      "website" : "https://www.ncbi.nlm.nih.gov/pubmed"
   },
   "PR" : {
      "description" : "Protein Ontology",
      "name" : "PR",
      "url_syntax" : "https://purl.obolibrary.org/obo/PR_[example_id]",
      "website" : "https://proconsortium.org/"
   },
   "PRINTS" : {
      "description" : "PRINTS compendium of protein fingerprints",
      "name" : "PRINTS",
      "url_syntax" : "http://www.bioinf.manchester.ac.uk/cgi-bin/dbbrowser/sprint/searchprintss.cgi?display_opts=Prints&category=None&queryform=false&regexpr=off&prints_accn=[example_id]",
      "website" : "http://www.bioinf.manchester.ac.uk/dbbrowser/PRINTS/"
   },
   "PUBMED" : {
      "description" : "PubMed",
      "name" : "PUBMED",
      "url_syntax" : "http://www.ncbi.nlm.nih.gov/pubmed/[example_id]",
      "website" : "https://www.ncbi.nlm.nih.gov/pubmed"
   },
   "Pfam" : {
      "description" : "Pfam database of protein families",
      "name" : "Pfam",
      "url_syntax" : "https://www.ebi.ac.uk/interpro/entry/pfam/[example_id]",
      "website" : "https://www.ebi.ac.uk/interpro/entry/pfam"
   },
   "PomBase" : {
      "description" : "PomBase",
      "name" : "PomBase",
      "url_syntax" : "https://www.pombase.org/gene/[example_id]",
      "website" : "https://www.pombase.org/"
   },
   "Prosite" : {
      "description" : "Prosite database of protein families and domains",
      "name" : "Prosite",
      "url_syntax" : "https://prosite.expasy.org/[example_id]",
      "website" : "https://prosite.expasy.org/"
   },
   "RGD" : {
      "description" : "Rat Genome Database",
      "name" : "RGD",
      "url_syntax" : "https://rgd.mcw.edu/rgdweb/search/search.html?term=[example_id]",
      "website" : "https://rgd.mcw.edu/"
   },
   "RHEA" : {
      "description" : "Rhea, the Annotated Reactions Database",
      "name" : "RHEA",
      "url_syntax" : "https://www.rhea-db.org/rhea/[example_id]",
      "website" : "https://www.rhea-db.org"
   },
   "SGD" : {
      "description" : "Saccharomyces Genome Database",
      "name" : "SGD",
      "url_syntax" : "https://www.yeastgenome.org/search?q=[example_id]&is_quick=true",
      "website" : "http://www.yeastgenome.org/"
   },
   "SMART" : {
      "description" : "Simple Modular Architecture Research Tool",
      "name" : "SMART",
      "url_syntax" : "http://smart.embl-heidelberg.de/smart/do_annotation.pl?BLAST=DUMMY&DOMAIN=[example_id]",
      "website" : "http://smart.embl-heidelberg.de/"
   },
   "SO" : {
      "description" : "Sequence Ontology",
      "name" : "SO",
      "url_syntax" : "http://www.sequenceontology.org/browser/current_svn/term/SO:[example_id]",
      "website" : "http://www.sequenceontology.org/"
   },
   "SUPERFAMILY" : {
      "description" : "SUPERFAMILY protein annotation database",
      "name" : "SUPERFAMILY",
      "url_syntax" : "http://supfam.org/scop/[example_id]",
      "website" : "http://supfam.org/"
   },
   "TAIR" : {
      "description" : "The Arabidopsis Information Resource",
      "name" : "TAIR",
      "url_syntax" : "http://arabidopsis.org/servlets/TairObject?accession=[example_id]",
      "website" : "http://www.arabidopsis.org/"
   },
   "TIGRFAMS" : {
      "description" : "TIGRFAMs HMM collection at the J. Craig Venter Institute",
      "name" : "TIGRFAMS",
      "url_syntax" : "http://tigrfams.jcvi.org/cgi-bin/HmmReportPage.cgi?acc=[example_id]",
      "website" : "http://tigrfams.jcvi.org/cgi-bin/index.cgi"
   },
   "UniPathway" : {
      "description" : "UniPathway",
      "name" : "UniPathway",
      "url_syntax" : "http://www.grenoble.prabi.fr/obiwarehouse/unipathway/upa?upid=[example_id]",
      "website" : "http://www.grenoble.prabi.fr/obiwarehouse/unipathway"
   },
   "UniProtKB" : {
      "description" : "Universal Protein Knowledgebase",
      "name" : "UniProtKB",
      "url_syntax" : "https://www.uniprot.org/uniprot/[example_id]",
      "website" : "http://www.uniprot.org"
   },
   "UniProtKB-KW" : {
      "description" : "UniProt Knowledgebase keywords",
      "name" : "UniProtKB-KW",
      "url_syntax" : "https://www.uniprot.org/keywords/[example_id]",
      "website" : "http://www.uniprot.org/keywords/"
   },
   "UniProtKB-SubCell" : {
      "description" : "UniProt Knowledgebase Subcellular Location vocabulary",
      "name" : "UniProtKB-SubCell",
      "url_syntax" : "http://www.uniprot.org/locations/[example_id]",
      "website" : "https://www.uniprot.org/locations/"
   },
   "UniRule" : {
      "description" : "Manually curated rules for automatic annotation of UniProtKB unreviewed entries",
      "name" : "UniRule",
      "url_syntax" : "https://www.uniprot.org/unirule/[example_id]",
      "website" : "https://www.uniprot.org/unirule/"
   },
   "WB" : {
      "description" : "WormBase database of nematode biology",
      "name" : "WB",
      "url_syntax" : "http://www.wormbase.org/get?name=[example_id]",
      "website" : "http://www.wormbase.org/"
   },
   "Xenbase" : {
      "description" : "Xenbase",
      "name" : "Xenbase",
      "url_syntax" : "http://www.xenbase.org/entry/[example_id]",
      "website" : "http://www.xenbase.org/"
   },
   "ZFIN" : {
      "description" : "Zebrafish Information Network",
      "name" : "ZFIN",
      "url_syntax" : "http://zfin.org/[example_id]",
      "website" : "http://zfin.org/"
   },
   "dictyBase" : {
      "description" : "dictyBase",
      "name" : "dictyBase",
      "url_syntax" : "http://dictybase.org/gene/[example_id]",
      "website" : "http://dictybase.org"
   },
   "iPTMnet" : {
      "description" : "iPTMnet protein post-translational modification database",
      "name" : "iPTMnet",
      "url_syntax" : "https://research.bioinformatics.udel.edu/iptmnet/entry/[example_id]/",
      "website" : "https://research.bioinformatics.udel.edu/iptmnet/"
   }
}

