<div *ngIf="displayChromosomes.length != 0" class="chr-overview">
  <table>
    <thead>
      <tr>
        <th>
          Name
        </th>
        <th>
          Gene count
        </th>
        <th>
          Coding genes
        </th>
        <th>
          ENA/GenBank ID
        </th>
        <th>
          Length
        </th>
      </tr>
    </thead>
    <tr *ngFor="let chr of displayChromosomes">
      <td>
        {{chr.displayName}}
      </td>
      <td>
        <a *ngIf="chr.geneCount != 0"
           title="Click to view genes from {{chr.long_display_name}}"
           routerLink="{{makeGenesLink(chr)}}">{{chr.geneCount}}</a>
        <span *ngIf="chr.geneCount == 0">0</span>
      </td>
      <td>
        <a *ngIf="chr.codingGeneCount != 0"
           title="Click to view coding genes from {{chr.long_display_name}}"
           routerLink="{{makeCodingGenesLink(chr)}}">{{chr.codingGeneCount}}</a>
        <span *ngIf="chr.codingGeneCount == 0">0</span>
      </td>
      <td>
        <a *ngIf="chr.enaUrl" href="{{chr.enaUrl}}">{{chr.enaId}}</a>
        <span *ngIf="!chr.enaUrl">{{chr.enaId}}</span>
      </td>
      <td>
        {{chr.length}}
      </td>
      <td>
        <div class="chr-bar" routerLink="{{makeGenesLink(chr)}}"
             [style.width.vw]="chrBarWidth(chr)">
        </div>
      </td>
    </tr>
    <tfoot>
      <tr>
        <td>
          Totals
        </td>

        <td>
          {{totalGeneCount}}
        </td>
        <td colspan="2">
          {{totalCodingGeneCount}}
        </td>
        <td>
          {{totalLength}}
        </td>
      </tr>
    </tfoot>
  </table>
</div>
