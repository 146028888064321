<div>
  <app-loading-spinner *ngIf="!slimSubset && !apiError"></app-loading-spinner>
  <app-api-error-display *ngIf="apiError" [error]="apiError"></app-api-error-display>

  <div *ngIf="slimSubset">
  <table class="slim-table">
    <thead>
      <th>
        <a *ngIf="sortColumnName != 'name'" (click)="setSortColumn('name')" title="Click to sort by name">
          Name <img title="Click to sort by name" src="assets/sort_both.svg">
        </a>
        <span *ngIf="sortColumnName == 'name'" title="Sorted by name">
          Name <img title="Sorted by name" src="assets/sort_up.svg">
        </span>
      </th>
      <th>Term</th>
      <th>
        <a *ngIf="sortColumnName != 'gene_count'" (click)="setSortColumn('gene_count')" title="Click to sort by gene count">
          Genes <img title="Click to sort by gene count" src="assets/sort_both.svg">
        </a>
        <span *ngIf="sortColumnName == 'gene_count'" title="Sorted by gene count">
          Genes <img title="Sorted by gene count" src="assets/sort_up.svg">
        </span>
      </th>
      <th *ngIf="slimConfig.external_link_config">{{slimConfig.external_link_config.description}}</th>
    </thead>
    <tbody>
      <tr *ngFor="let element of slimSubsetElements">
        <td>{{element.name}}</td>
        <td>
          <a routerLink="/term/{{element.termid}}" routerLinkActive="active">{{element.termid}}</a>
        </td>
        <td>
          <a *ngIf="cvConfig.feature_type == 'gene'" routerLink="/term_genes/{{element.termid}}" routerLinkActive="active">{{element.gene_count}}</a>
          <a *ngIf="cvConfig.feature_type != 'gene'" routerLink="/term_single_locus_genotype_genes/{{element.termid}}" routerLinkActive="active">{{element.single_multi_gene_count}}</a>
        </td>
        <td *ngIf="slimConfig.external_link_config">
          <app-external-link [identifier]="element.termid"
                             [linkText]="slimConfig.external_link_config.link_text"
                             [linkConfigKey]="slimConfig.external_link_config.link_config_key"
                             [iconImage]="slimConfig.external_link_config.icon_image">
          </app-external-link>
        </td>
      </tr>
    </tbody>
  </table>

  <div id="slim-gene-totals">
    <div id="slim-gene-totals-header">
      Total slimmed gene products (protein and ncRNA): {{slimSubset.total_gene_count}}
    </div>

    Note that the counts in the "Genes" column are not additive,
    because many gene products are annotated to more than one term in
    the slim.
  </div>

  </div>

  <div *ngIf="nonSlimWithAnnotation" id="non-slim-genes">
    <div id="non-slim-total">Protein coding genes not covered by the slim
    ({{nonSlimWithAnnotation.elements.length + nonSlimWithoutAnnotation.elements.length}} in total):</div>
    <div>
      {{nonSlimWithAnnotation.display_name}}:
      <a routerLink="/gene_subset/{{nonSlimWithAnnotationName}}"
         routerLinkActive="active">{{nonSlimWithAnnotation.elements.length}}</a>
    </div>
    <div>
      {{nonSlimWithoutAnnotation.display_name}}:
      <a routerLink="/gene_subset/{{nonSlimWithoutAnnotationName}}"
         routerLinkActive="active">{{nonSlimWithoutAnnotation.elements.length}}</a>
    </div>
  </div>
</div>
