<div class="page-content-menu"
     [ngClass]="{'menu-pos-fixed': menuPositionFixed, 'menu-pos-absolute': !menuPositionFixed}">
  <a [ngClass]="{'small-logo-visible': menuPositionFixed, 'small-logo-hidden': !menuPositionFixed}"
     routerLink="/" title="{{siteName}} home">
    <img src="assets/{{smallLogoFileName}}" alt="{{siteName}} home" />
  </a>
  <div class="left-menu-part left-menu-header">
    <a *ngIf="!titleRoute" (click)="scrollToPageTop()">{{title}}</a>
    <a *ngIf="titleRoute" routerLink="{{titleRoute}}">{{title}}</a>
  </div>

  <ng-content></ng-content>
</div>
